@import '../../styles/mixins';

.newVersion {
  position: fixed;
  bottom: 0;
  right: 0;
  background-image: url('../../../assets/popover-blur-blue.png'), url('../../../assets/popover-blur-red.png');
  background-repeat: no-repeat, no-repeat;
  background-size: 50%, 50%;
  background-position: right top, left bottom;
  backdrop-filter: blur(20px);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden;
  box-shadow: rgba(145, 158, 171, 0.5) 0 0 2px 0,rgba(145, 158, 171, 0.5) -20px 20px 40px -4px;
  border-radius: 16px;
  margin: 24px;
  padding: 12px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  font-size: 0.875rem;
}