@import '../../../../styles/mixins';

.userInfoContainer {
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  text-align: center;

  @include bp (l) {
    padding: 40px 16px;
  }

  &.collapsed {
    display: none;
  }

  .userInfoContainerInner {
    display: flex;
    flex-direction: column;
    align-items: center;

    .avatarContainer {
      position: relative;

      .avatar {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: inherit;
        font-size: 1.25rem;
        line-height: 1;
        border-radius: 50%;
        overflow: hidden;
        user-select: none;
        width: 48px;
        height: 48px;
      }
    }

    .nameContainer {
      display: flex;
      flex-direction: column;
      margin-bottom: 24px;
      margin-top: 12px;
      width: 100%;
      gap: 4px;

      .name {
        margin: 0;
        font-family: inherit;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.57143;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .email {
        margin: 0;
        font-family: inherit;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.57143;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .systemContainer {
      display: flex;
      flex-direction: column;
      margin-top: 24px;
      width: 100%;
      gap: 4px;

      .logoContainer {
        height: 16px;
        width: auto;

        img {
          height: 100%;
          width: auto;
        }
      }

      .version {
        margin: 0;
        font-family: inherit;
        font-weight: 400;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}