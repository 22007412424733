@import '../../../styles/mixins';

.sidebarContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: fixed;
  width: 320px;
  border-right: none;
  background-image: url('../../../../assets/popover-blur-blue.png'),
    url('../../../../assets/popover-blur-red.png');
  background-repeat: no-repeat, no-repeat;
  background-size: 50%, 50%;
  background-position: right top, left bottom;
  backdrop-filter: blur(20px);

  @include bp(l) {
    width: 280px;
    background: none;
    backdrop-filter: none;
    border-right: 1px dashed;
  }

  &.collapsed {
    width: 88px;

    .sidebarContainerInner {
      .sidebarContainerScrollable {
        .logoContainer {
          display: flex;
          justify-content: center;
          margin: 16px auto;
        }
      }
    }
  }

  .sidebarContainerInner {
    flex-grow: 1;
    height: 100%;
    overflow: hidden;

    .sidebarContainerScrollable {
      max-height: 100%;
      height: 100%;

      .logoContainer {
        width: 40px;
        height: 40px;
        display: inline-flex;
        margin-top: 24px;
        margin-left: 32px;
        margin-bottom: 8px;
      }

      .navigation {
        display: flex;
        flex-direction: column;
        min-height: calc(100vh - 30px - 286px);

        @include bp(l) {
          min-height: calc(100vh - 30px - 316px);
        }
      }

      .postNavigation {
        flex-grow: 1;
      }
    }
  }
}
