@import '../../../../styles/mixins';

.companySelect {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  color: inherit;
  font-family: inherit;
  padding: 4px 8px;
  gap: 4px;

  @include bp(s) {
    gap: 8px;
  }

  .companyImage {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    max-width: 100%;
    vertical-align: middle;
  }

  .companyName {
    display: none;
    font-family: inherit;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.57143;

    @include bp(s) {
      display: inline-flex;
    }
  }

  .arrowsIcon {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    display: inline-flex;
  }
}

.popoverArrow {
  width: 14px;
  height: 14px;
  position: absolute;
  border-bottom-left-radius: 3.5px;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  border: 1px solid;
  backdrop-filter: blur(6px);
  top: -6.5px;
  transform: rotate(135deg);
  left: 10px;
}

.companyItem {
  height: 48px;
  border-radius: 6px;
  padding: 6px 8px;
  line-height: 1.57143;
  font-size: 0.875rem;
  font-weight: 400;
  gap: 16px;

  .companyLogo {
    height: 24px;
    width: 24px;
    border-radius: 50%;
  }

  .companyItemName {
    flex-grow: 1;
    font-weight: 500;
  }
}