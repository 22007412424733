@import '../../../../styles/mixins';

.languageButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  outline: 0;
  border: 0;
  margin: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  text-align: center;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  width: 40px;
  height: 40px;

  &:hover {
    background-color: rgba(99, 115, 129, 0.08);
    transform: scale(1.05);
  }
}

.popoverArrow {
  width: 14px;
  height: 14px;
  position: absolute;
  border-bottom-left-radius: 3.5px;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  border: 1px solid;
  backdrop-filter: blur(6px);
  top: -6.5px;
  transform: rotate(135deg);
  right: 90px;
}

.languageItem {
  min-height: 32px !important;
  gap: 16px;

  @include bp(l) {
    min-height: 40px !important;
  }

  &:not(:last-of-type) {
    margin-bottom: 4px;
  }

  .countryFlag {
    flex-shrink: 0;
    overflow: hidden;
    display: inline-flex;
    justify-content: center;
    align-content: center;
    font-size: 20px;
    text-align: center;
    margin-top: -2px;
  }

  .countryName {
    font-size: 0.875rem;
    line-height: 1.57143;
    font-weight: 400;
  }

  &.isSelected {
    background-color: rgba(145 158 171 / 0.16);

    .countryName {
      font-weight: 600;
    }
  }
}
