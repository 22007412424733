@mixin bp($point) {
  @if $point == s {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $point == m {
    @media (min-width: 900px) {
      @content;
    }
  } @else if $point == l {
    @media (min-width: 1200px) {
      @content;
    }
  } @else {
    @media (min-width: $point) {
      @content;
    }
  }
}
