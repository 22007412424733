@import '../../styles/mixins';

.mainBoxContainerForPage {
  flex-direction: row;
  min-height: 100%;
  display: flex;

  .mainBoxContainerForSidebar {
    flex-shrink: 0;
    width: 280px;
    display: none;
    transition: width 0.5s ease;

    @include bp(l) {
      display: flex;
    }

    &.collapsed {
      width: 88px;

      .sidebarExpandButton {
        top: 22px;
        left: 72px;
      }
    }

    .sidebarExpandButton {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      outline: 0;
      margin: 0;
      cursor: pointer;
      user-select: none;
      vertical-align: middle;
      appearance: none;
      text-decoration: none;
      text-align: center;
      border-radius: 50%;
      overflow: visible;
      transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      font-size: 1.125rem;
      padding: 4px;
      top: 32px;
      position: fixed;
      left: 264px;
      z-index: 1101;
      border: 1px dashed;
      backdrop-filter: blur(6px);
    }
  }

  .mainContainer {
    flex-grow: 1;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    padding: 88px 16px;
    width: calc(100% - 280px);
    transition: width 0.5s ease;

    &.collapsedMainContainer {
      width: calc(100% - 88px);
    }
  }
}
