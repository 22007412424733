.navigationGroup {
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;

  &.collapsed {
    padding: 0;
  }

  .navigationGroupName {
    box-sizing: border-box;
    position: relative;
    list-style: none;
    font-size: 0.75rem;
    cursor: pointer;
    font-weight: 700;
    line-height: 1.5;
    text-transform: uppercase;
    font-family: "Public Sans", sans-serif;
    display: inline-flex;
    align-items: center;
    align-self: flex-start;
    gap: 8px;
    margin-bottom: 4px;
    padding: 16px 8px 8px 12px;
    transition: color 300ms cubic-bezier(0.4, 0, 0.2, 1), padding-left 300ms cubic-bezier(0.4, 0, 0.2, 1);

    &:hover {
      padding-left: 16px;
      color: #1C252E;

      .expandIcon {
        opacity: 1;
      }
    }

    .expandIcon {
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      display: inline-flex;
      left: -4px;
      opacity: 0;
      position: absolute;
      transition: opacity 300ms cubic-bezier(0.4, 0, 0.2, 1);
    }
  }
}
