@import '../../../../styles/mixins';

.profileButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  outline: 0;
  border: 0;
  margin: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  text-align: center;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  width: 40px;
  height: 40px;
  background: rgba(145, 158, 171, 0.08);

  &:hover {
    background-color: rgba(99, 115, 129, 0.08);
    transform: scale(1.05);
  }

  .avatar {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-shrink: 0;
    font-size: 1.25rem;
    line-height: 1;
    border-radius: 50%;
    overflow: hidden;
    user-select: none;
    width: 36px;
    height: 36px;
    border: 2px solid;

    img {
      width: 100%;
      height: 100%;
      text-align: center;
      object-fit: cover;
      color: transparent;
      text-indent: 10000px;
    }
  }
}

.popoverArrow {
  width: 14px;
  height: 14px;
  position: absolute;
  border-bottom-left-radius: 3.5px;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  border: 1px solid;
  backdrop-filter: blur(6px);
  top: -6.5px;
  transform: rotate(135deg);
  right: 44px;
}

.userNameContainer {
  padding: 16px 16px 12px;

  h6 {
    margin: 0;
    font-weight: 600;
    line-height: 1.57143;
    font-size: 0.875rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  p {
    margin: 0;
    line-height: 1.57143;
    font-size: 0.875rem;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.divider {
  border-width: 0 0 thin;
  border-style: dashed;
}

.profileItem {
  min-height: 32px !important;

  @include bp(l) {
    min-height: 40px !important;
  }

  &:not(:last-of-type) {
    margin-bottom: 4px;
  }

  &.logoutButton {
    margin: 8px;
    font-weight: 700;
  }
}