@import '../../styles/mixins';

.authLayoutContainer {
  height: 100%;

  .mainContainer {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    position: relative;

    &::before {
      opacity: 0.24;
      width: 100%;
      height: 100%;
      z-index: -1;
      content: "";
      position: absolute;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      background-image: url("../../../assets/overlay_mobile.jpg");

      @include bp(m) {
        opacity: 0;
      }
    }

    .authInnerPart {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      max-width: 480px;
      padding-left: 16px;
      padding-right: 16px;
      gap: 24px;

      @include bp(m) {
        padding-left: 64px;
        padding-right: 64px;
        padding-bottom: 32px;
        justify-content: space-between;
        gap: 16px;
      }

      .logoMainLink {
        margin: 0;
        font: inherit;
        text-decoration: none;
        display: contents;

        .logoLink {
          display: inline-flex;
          width: 40px;
          height: 40px;
          margin-top: 16px;
          margin-bottom: 80px;

          @include bp (m) {
            margin-top: 64px;
            margin-bottom: 64px;
          }

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .outletContainer {
        padding: 40px 24px;
        border-radius: 16px;

        @include bp(m) {
          box-shadow: none;
          padding: 0;
          overflow: unset;
        }
      }

      .systemContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 24px;

        @include bp(m) {
          padding-bottom: 0;
        }

        .logoContainer {
          height: 16px;
          width: auto;

          img {
            height: 100%;
            width: auto;
          }
        }
      }
    }

    .mainImageContainer {
      display: none;

      @include bp(m) {
        display: flex;
        flex-direction: column;
        -webkit-box-flex: 1;
        flex-grow: 1;
        position: relative;
      }

      img {
        top: 16px;
        left: 16px;
        object-fit: cover;
        position: absolute;
        width: calc(100% - 32px);
        height: calc(100% - 32px);
      }
    }
  }
}