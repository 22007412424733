.sidebarButton {
  margin: 0;
  font: inherit;
  text-decoration: none;
  color: inherit;

  .itemButton {
    outline: 0;
    border: 0;
    margin: 0 0 4px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    text-decoration: none;
    min-width: 0;
    box-sizing: border-box;
    text-align: left;
    transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 8px;
    padding: 4px 8px 4px 12px;
    min-height: 44px;

    &:hover {
      text-decoration: none;
      background-color: rgba(145, 158, 171, 0.08);
    }

    &.collapsed {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 4px;
      border-radius: 6px;
      margin: 4px 4px;
      text-align: center;

      .iconContainer {
        margin: 0;
      }

      .labelContainer {
        .label {
          line-height: 16px;
          font-size: 10px;
          font-weight: 600;
          margin-top: 2px;
        }
      }
    }

    &.active {
      .labelContainer {
        .label {
          font-weight: 600;
        }
      }
    }

    .iconContainer {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      margin-right: 16px;
    }

    .labelContainer {
      flex: 1 1 auto;
      min-width: 0;

      .label {
        width: 100%;
        max-width: 100%;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: 1.57143;
        font-size: 0.875rem;
        font-family: "Public Sans", sans-serif;
        font-weight: 500;
        text-transform: capitalize;
      }
    }
  }
}
