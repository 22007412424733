@import '../../../styles/mixins';

.adminNavigationContainer {
  background-image: none !important;
  box-shadow: none !important;
  height: 64px !important;
  z-index: 1101 !important;
  backdrop-filter: blur(6px) !important;
  transition: height 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;

  @include bp(l) {
    height: 80px !important;
  }

  .adminNavigationInner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    min-height: 64px;
    padding-left: 16px;
    padding-right: 16px;

    @include bp(l) {
      padding-left: 40px;
      padding-right: 40px;
    }

    .leftActions {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      align-items: center;
      justify-content: flex-start;
    }

    .rightActions {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      align-items: center;
      justify-content: flex-end;
      gap: 6px;
    }
  }

}
