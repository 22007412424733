@import '../../../../styles/mixins';

.settingsButton {
  .icon {
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }

    display: inline-block;
    animation: spin 10s linear infinite;
    width: 24px;
    height: 24px;
  }
}
