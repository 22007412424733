@import '../../../../styles/mixins';

.navigationDrawer {
  display: block;

  img {
    width: 24px;
    height: 24px;
  }

  @include bp(l) {
    display: none !important;
  }
}

.drawerContainer {
  width: 320px;

  :global(.MuiDrawer-paper) {
    width: 320px;
  }
}